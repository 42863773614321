import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class CostPerFeatureTrendChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    // sort the data by the time value
    this.parsedGraphData.data.sort((a, b) => a[0] - b[0])

    const total = this.parsedGraphData.data.reduce((acc, curr) => {
      return acc + curr[1]
    }, 0)

    const series = [
      {
        data: this.parsedGraphData.data,
        name: 'Cost per New Unit of Code',
        marker: {
          enabled: false
        },
        lineColor: this.colorTheme.primary,
        color: this.colorTheme.primary,
        type: 'areaspline',
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [
              0,
              Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(this.darkMode ? 0.3 : 0.2)
                .get('rgba')
            ],
            [
              1,
              Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(0)
                .get('rgba')
            ]
          ]
        },
        zoneAxis: 'x'
      }
    ]

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        sync: true,
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b \'%y', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: controller.tailwindColors.gray[100],
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: {
          text: null
        },
        labels: {
          format: '${text}', /* eslint-disable-line no-template-curly-in-string */
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return `$${this.value}`
            }
          }
        },
        ...maxSettings
      },
      plotOptions: {
        series: {
          connectNulls: true,
          cursor: 'pointer',
          stickyTracking: false
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        formatter: function () {
          return card({
            date: Highcharts.dateFormat('%b \'%y', new Date(this.key)),
            primaryValues: [
              primaryValue({
                name: 'Cost per New Unit of Code',
                value: `$${Number(this.y).toLocaleString('en-US')}`
              })
            ]
          })
        },
        style: {
          zIndex: 100
        }
      },
      series: series
    }
  }
}
