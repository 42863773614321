import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class PortfolioOverviewChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    // using base64 encoded svg
    const noDataBackgroundImageUrl = 'data:image/svg+xml,' + encodeURIComponent(`<svg width="634" height="140" opacity="0.1" viewBox="0 0 634 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M634 4C634 1.79086 632.209 0 630 0H579C576.791 0 575 1.79086 575 4V139H634V4Z" fill="#9CA3AF"/>
      <path d="M562 34C562 31.7909 560.209 30 558 30H507C504.791 30 503 31.7909 503 34V139H562V34Z" fill="#9CA3AF"/>
      <path d="M490 66.5869C490 64.3778 488.209 62.5869 486 62.5869H435C432.791 62.5869 431 64.3778 431 66.5869V139H490V66.5869Z" fill="#9CA3AF"/>
      <path d="M417.616 40.5254C417.616 38.3163 415.826 36.5254 413.616 36.5254H362.616C360.407 36.5254 358.616 38.3163 358.616 40.5254V139.167H417.616V40.5254Z" fill="#9CA3AF"/>
      <path d="M345.519 73.3896C345.519 71.1805 343.728 69.3896 341.519 69.3896H290.518C288.309 69.3896 286.518 71.1805 286.518 73.3896V139.166H345.519V73.3896Z" fill="#9CA3AF"/>
      <path d="M273.422 10.2197C273.422 8.01058 271.631 6.21973 269.422 6.21973H218.421C216.212 6.21973 214.421 8.01059 214.421 10.2197V139.167H273.422V10.2197Z" fill="#9CA3AF"/>
      <path d="M201.324 81.4805C201.324 79.2713 199.534 77.4805 197.324 77.4805H146.324C144.115 77.4805 142.324 79.2713 142.324 81.4805V139.166H201.324V81.4805Z" fill="#9CA3AF"/>
      <path d="M57.1292 101.333C57.1292 99.1239 55.3383 97.333 53.1292 97.333H3.99966C1.79052 97.333 -0.000339508 99.1239 -0.000339508 101.333V139.167H57.1292V101.333Z" fill="#9CA3AF"/>
      <path d="M129.227 57.0527C129.227 54.8436 127.436 53.0527 125.227 53.0527H74.2263C72.0172 53.0527 70.2263 54.8436 70.2263 57.0527V139.167H129.227V57.0527Z" fill="#9CA3AF"/>
    </svg>`)

    // highlight the column if the portfolio_view is false and current_organization is equal to the org_id
    if (controller.optionsValue.portfolio_view === false && controller.optionsValue.current_organization_id) {
      // find point where org id is equal to current_organization_id
      const dataPoint = controller.parsedGraphData.graph_data.find((point) => {
        return point.org_id === controller.optionsValue.current_organization_id
      })

      // set color to blue 400
      if (dataPoint) dataPoint.color = controller.tailwindColors.blue[400]
    }

    // get the max y value
    let maxY = Math.max(
      ...controller.parsedGraphData.graph_data.map((point) => point.y),
      controller.parsedGraphData.median,
      controller.parsedGraphData.global_median
    )

    // round up to the nearest 10
    maxY = Math.ceil(maxY / 10) * 10

    const plotBackgroundImageObject = {}
    // check if graph_data is empty, if it is add the no data background image
    if (controller.parsedGraphData.graph_data.length === 0) plotBackgroundImageObject.plotBackgroundImage = noDataBackgroundImageUrl

    return {
      chart: {
        ...plotBackgroundImageObject,
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 0,
        height: 225
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        // Adding distinct categories prevents labels from disappearing when the chart is resized.
        categories: controller.parsedGraphData.graph_data.map((point) => point.org_id),
        labels: {
          useHTML: true,
          allowOverlap: true,
          autoRotation: 0,
          formatter: function () {
            const point = controller.parsedGraphData.graph_data[this.pos]
            const imageUrl = point.logo
            const nameAbbr = point.name_abbreviation
            let imageHtml = ''
            if (imageUrl) {
              imageHtml = `<div class="hidden md:block md:h-4 md:w-4 lg:h-5 lg:w-5"><img src="${imageUrl}" class="rounded-md hidden md:block md:h-4 md:w-4 lg:h-5 lg:w-5" /></div>`
            } else if (nameAbbr) {
              imageHtml = `<div class="items-center justify-center hidden md:flex md:h-4 md:w-4 lg:h-5 lg:w-5 font-black text-[10px] text-center text-white align-middle bg-blue-500 rounded-md shrink-0">${nameAbbr}</div> `
            }
            return imageHtml
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        tickWidth: 0
      },
      yAxis: {
        min: 0,
        max: maxY,
        showEmpty: true,
        tickInterval: 10,
        title: { text: null },
        labels: {
          style: {
            color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[600]
          }
        },
        gridLineColor: 'transparent',
        plotLines: [
          {
            color: controller.tailwindColors.teal[500],
            value: controller.parsedGraphData.median === 0 ? null : controller.parsedGraphData.median,
            width: 2
          },
          {
            color: controller.tailwindColors.purple[400],
            value: controller.parsedGraphData.global_median === 0 ? null : controller.parsedGraphData.global_median,
            width: 2
          }
        ]
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          minPointLength: 3,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          maxPointWidth: 100
        }
      },
      series: [
        {
          data: controller.parsedGraphData.graph_data,
          color: controller.tailwindColors.blue[200],
          states: {
            hover: {
              color: controller.tailwindColors.blue[400]
            }
          }
        }
      ],
      tooltip: {
        ...controller.baseToolTipConfig,
        outside: false,
        style: { pointerEvents: 'auto' },
        formatter: function () {
          const trendingUpIcon = `
          <svg class="text-green-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="currentColor" stroke-width="1.58594" stroke-linecap="round" stroke-linejoin="round" d="M8.66667 4.66699H14M14 4.66699V10.0003M14 4.66699L8.66667 10.0003L6 7.33366L2 11.3337" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          `
          const trendingDownIcon = `
          <svg class="text-red-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="currentColor" stroke-width="1.58594" stroke-linecap="round" stroke-linejoin="round" d="M8.66667 11.333H14M14 11.333V5.99967M14 11.333L8.66667 5.99967L6 8.66634L2 4.66634"/>
          </svg>
          `
          const noChangeIcon = `
          <svg class="text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="currentColor" stroke-width="1.58594" stroke-linecap="round" stroke-linejoin="round" d="M1.12207 7.91043L5.62848 5.93604L10.3721 10.0643L14.8786 7.91043"/>
          </svg>
          `
          const imageUrl = this.point.options.logo
          const nameAbbr = this.point.options.name_abbreviation
          let imageHtml = ''
          if (imageUrl) {
            imageHtml = `<div class="h-4 w-4"><img src="${imageUrl}" class="h-4 w-4 rounded-md" /></div> `
          } else if (nameAbbr) {
            imageHtml = `<div class="flex items-center justify-center w-4 h-4 font-black text-[10px] text-center text-white align-middle bg-blue-500 rounded-md shrink-0">${nameAbbr}</div> `
          }

          const header = `<div class="flex items-center gap-2 text-xs mb-1">
            ${imageHtml}
            <h3 class="font-semibold text-gray-600 dark:text-gray-400">${this.point.options.name || 'Anonymous'}</h3>
          </div>`

          let featuresTrendIcon = trendingDownIcon
          if (this.point.options.features_per_eng_difference === 0) {
            featuresTrendIcon = noChangeIcon
          } else if (this.point.options.features_per_eng_difference > 0) {
            featuresTrendIcon = trendingUpIcon
          }

          const primaryValues = []
          primaryValues.push(primaryValue({
            name: 'New Units of Code per Developer',
            value: `<span class="flex items-center gap-1 ml-auto">${Math.round(this.point.y * 10) / 10} ${featuresTrendIcon}</span>`
          }))

          let sizeTrendIcon = trendingDownIcon
          if (this.point.options.org_size_difference === 0) {
            sizeTrendIcon = noChangeIcon
          } else if (this.point.options.org_size_difference > 0) {
            sizeTrendIcon = trendingUpIcon
          }

          let orgSizeDisplay = ''
          if (this.point.options.show_org_identity) {
            orgSizeDisplay = this.point.options.org_size
          } else {
            orgSizeDisplay = this.point.options.bucketed_org_size
          }

          primaryValues.push(primaryValue({
            name: 'Developers',
            value: `<span class="flex items-center gap-1 ml-auto">${orgSizeDisplay} ${sizeTrendIcon}</span>`
          }))

          let footer = ''
          if (this.point.options.show_org_identity) {
            footer = `<a data-turbo-frame="_top" class="underline text-blue-500 text-xs" href="${this.point.options.company_report_route || `/companies/${this.point.options.slug}`}">Click to view full report</a>`
          }

          return card({
            primaryValues,
            header,
            footer
          })
        }
      }
    }
  }

  legendMouseOver (orgId) {
    const series = this.chart.series[0].data.find(series => series.org_id === orgId)
    series?.setState('hover')
    if (series) this.chart.tooltip.refresh(series)
  }

  legendMouseOut (orgId) {
    const series = this.chart.series[0].data.find(series => series.org_id === orgId)
    series?.setState('normal')
    this.chart.tooltip.hide()
  }
}
