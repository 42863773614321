import { Controller } from '@hotwired/stimulus'
import { setUrlParams } from '../helpers/url_helper'

export default class extends Controller {
  static targets = ['splineChart', 'columnChart', 'splineChartButton', 'columnChartButton', 'graphContainer', 'tableContainer']
  static outlets = ['scroll-to']
  static values = {
    chartType: { type: String, default: 'column' }
  }

  scrollInContainer () {
    if (this.hasScrollToOutlet) {
      this.scrollToOutlet.scrollToNamedContainer('scrollInContainer')
    }
  }

  columnChartTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'column')
  }

  splineChartTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'spline')
  }

  tableContainerTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'table')
  }

  graphContainerTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'graph')
  }

  showSplineChart () {
    this.showChartType(this.columnChartTargets, this.splineChartTargets, 'spline')
  }

  showColumnChart () {
    this.showChartType(this.splineChartTargets, this.columnChartTargets, 'column')
  }

  showTableContainer () {
    this.showChartType(this.graphContainerTargets, this.tableContainerTargets, 'table')
    const formChartTypeElements = document.querySelectorAll('[id="chart_type"]')
    formChartTypeElements.forEach((el) => {
      if (el.value === 'graph') {
        el.value = 'table'
      }
    })
  }

  showGraphContainer () {
    this.showChartType(this.tableContainerTargets, this.graphContainerTargets, 'graph')
    const formChartTypeElements = document.querySelectorAll('[id="chart_type"]')
    formChartTypeElements.forEach((el) => {
      if (el.value === 'table') {
        el.value = 'graph'
      }
    })
  }

  showChartType (targetsToHide, targetsToShow, type) {
    this.chartTypeValue = type
    targetsToHide.forEach(chart => { chart.classList.toggle('hidden', true) })
    targetsToShow.forEach(chart => { chart.classList.toggle('hidden', false) })
    setUrlParams('chart_type', type)
  }
}
