export function fetchSelectedGraphPeriod ({ selectedIndex, parsedGraphData, graphUrl }) {
  if (graphUrl.indexOf(window.location.origin) === -1) {
    graphUrl = window.location.origin + graphUrl
  }
  const url = new URL(graphUrl)
  const searchParams = new URLSearchParams(url.search)
  searchParams.set('table_date', parsedGraphData.table_dates[selectedIndex])
  const periodSelectUrl = [
    url.origin,
    url.pathname,
    '?',
    searchParams
  ].join('')
  window.location.href = periodSelectUrl
}
