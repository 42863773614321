import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['chevron']
  static classes = ['toggle']
  static values = { id: Array }

  connect () {
    this.toggleTargets = this.idValue.map((id) => {
      const element = document.getElementById(id)
      if (element) {
        return {
          element: element,
          toggleClasses: element.dataset.toggleClasses.split(' ')
        }
      }
      return null
    }).filter(target => target !== null)
    this.isVisibleValue = true
  }

  toggle () {
    this.isVisibleValue = !this.isVisibleValue
    this.render()
  }

  hide (event) {
    this.isVisibleValue = false
    this.render(event)
  }

  show (event) {
    this.isVisibleValue = true
    this.render(event)
  }

  render () {
    for (let i = 0; i < this.toggleTargets.length; i++) {
      const { element, toggleClasses } = this.toggleTargets[i]
      toggleClasses.forEach((toggleClass) => element.classList.toggle(toggleClass))
    }
    if (this.hasChevronTarget) this.chevronTarget.classList.toggle('rotate-180')
  }
}
