import { Controller } from '@hotwired/stimulus'

export default class ScrollToController extends Controller {
  static targets = ['scrollToElement', 'scrollInContainer']
  static values = {
    behavior: {
      type: String,
      default: 'smooth'
    },
    position: {
      type: String,
      default: 'center' // 'start', 'center', 'end', or 'nearest'
    }
  }

  scrollToElementTargetConnected () {
    this.scrollToElementTarget.scrollIntoView({ behavior: this.behaviorValue, block: this.positionValue })
  }

  scrollInContainerTargetConnected () {
    this.scrollInContainerTarget.scrollLeft += this.scrollInContainerTarget.scrollWidth
  }

  scrollToNamedContainer (containerName) {
    const container = document.querySelector(`[data-scroll-to-target="${containerName}"]`)
    if (container) {
      container.scrollLeft += container.scrollWidth
    }
  }
}
