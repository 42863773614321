import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class GroupReportFeaturesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const seriesKeys = [
      {
        key: 'features',
        name: 'New',
        color: this.categoricalColors[1],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'churn',
        name: 'Churn',
        color: this.categoricalColors[2],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'refactor',
        name: 'Refactor',
        color: this.categoricalColors[3],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'other',
        name: 'Other',
        color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'features_per_engineer',
        name: 'New units of code per developer',
        color: controller.tailwindColors.blue[500],
        type: 'spline',
        yAxis: 1
      }
    ]

    controller.tooltipValues = {}
    controller.parsedGraphData.data.forEach((data, i) => {
      controller.tooltipValues[Highcharts.dateFormat('%b %d', data.unix)] = { ...data.series, unix: data.unix }
    })

    const emptySeriesData = []
    let fpeTotal = 0
    let totalFeaturesTotal = 0
    const series = seriesKeys
      .map((config, i) => {
        const data = controller.parsedGraphData.data.map((data) => [Highcharts.dateFormat('%b %d', data.unix), data.series[config.key]])
        if (config.key !== 'features_per_engineer') {
          for (let i = 0; i < data.length; i++) {
            if (!data[i].series) {
              emptySeriesData.push([data[i].unix, 0])
            }
          }
        }

        if (config.key === 'features_per_engineer') {
          fpeTotal = data.reduce((acc, curr) => acc + curr[1], 0)
        } else {
          totalFeaturesTotal = data.reduce((acc, curr) => acc + curr[1], 0)
        }

        data[data.length - 1] = { name: data[data.length - 1][0], y: data[data.length - 1][1], ...this.getLastDataPointPattern({ bg: config.color }) }

        return {
          data,
          states: { hover: { color: config.color } },
          lineColor: config.color,
          zIndex: 1,
          marker: {
            enabled: false
          },
          stacking: 'normal',
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          ...config
        }
      })

    const fpeMaxSettings = this.getYAxisMaxAndTickAmountSettings(fpeTotal)
    const totalFeaturesMaxSettings = this.getYAxisMaxAndTickAmountSettings(totalFeaturesTotal)
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 30,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.data.map((data) => Highcharts.dateFormat('%b %d', data.unix)),
        title: { text: null },
        tickLength: 0,
        labels: {
          step: controller.parsedGraphData.data.length > 6 ? 2 : 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: [
        {
          title: { text: null },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && totalFeaturesTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...totalFeaturesMaxSettings
        },
        {
          title: { text: null },
          gridLineWidth: 0,
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && fpeTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...fpeMaxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.025,
          groupPadding: 0,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          states: {
            hover: {
              color: controller.darkMode
                ? controller.tailwindColors.blue[900]
                : controller.tailwindColors.blue[300]
            }
          },
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(
                    new Date(controller.tooltipValues[this.name].unix).toISOString().split('T')[0],
                    controller.tooltipValues[this.name].count_pull_requests
                  )
                }
              }
            }
          }
        }
      },
      colors: [
        controller.tailwindColors.blue[200],
        controller.tailwindColors.orange[200],
        controller.tailwindColors.teal[200]
      ],
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const showPercentage = this.points.length > 2
          const breakDownValues = this.points.reverse().map((p) => {
            const color = p.color.pattern ? p.color.pattern.color : p.color
            if (p.series.name === 'No data') {
              return null
            }
            const percentDisplay = showPercentage ? ` (${p.percentage.toFixed(0)}%)` : ''
            return breakDownValue({
              name: p.series.name,
              style: `background-color: ${color};`,
              value: p.series.name === 'New units of code per developer' ? p.y.toFixed(1) : `${p.y}${percentDisplay}`,
              type: p.series.name === 'New units of code per developer' ? 'line' : 'box'
            })
          }).reverse().filter(p => p)
          return card({
            date: controller.tooltipValues[this.key].date_range_display,
            breakDownValues,
            footer: controller.optionsValue.metrics_url && `<button data-value="${controller.tooltipValues[this.key].unix}" data-y-value="${controller.tooltipValues[this.key].count_pull_requests}" data-action="click->group-report-features-delivered-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`,
            incomplete: this.points[0].point.index === (controller.parsedGraphData.data.length - 1)
          })
        }
      }
    }
  }
}
