import BaseGraph from './base_graph_controller'
import { card, breakDownValue, primaryValue } from '../helpers/tooltip_helper'

export default class ProductivityAtScaleGraph extends BaseGraph {
  static outlets = ['date-range']

  prepareGraphSchema () {
    const controller = this
    const seriesDataMap = {
      features_p90: [],
      features_p75: [],
      features_p50: []
    }
    const categories = []
    controller.parsedGraphData.series.forEach(dataPoint => {
      categories.push(dataPoint.category)
      seriesDataMap.features_p90.push([dataPoint.category, dataPoint.features_p90])
      seriesDataMap.features_p75.push([dataPoint.category, dataPoint.features_p75])
      seriesDataMap.features_p50.push([dataPoint.category, dataPoint.features_p50])
    })
    let companyBenchmarkSeries = null

    // add a + to the last category to show it's a range
    categories[categories.length - 1] = `${categories[categories.length - 1]}+`
    if (controller.parsedGraphData.company_benchmark) {
      if (controller.parsedGraphData.company_benchmark.features_per_engineer > 0 &&
        controller.parsedGraphData.company_benchmark.team_size > 0
      ) {
        const teamSize = controller.parsedGraphData.company_benchmark.team_size

        let matched = false
        const benchmarkSeries = categories.map((category) => {
          const range = category.split('-').map(num => parseInt(num, 10))
          if (range.length === 1 && !matched) {
            return controller.parsedGraphData.company_benchmark.features_per_engineer
          } else if (teamSize >= range[0] && teamSize <= range[1] && !matched) {
            matched = true
            return controller.parsedGraphData.company_benchmark.features_per_engineer
          }
          return null
        })
        companyBenchmarkSeries = {
          name: controller.optionsValue.company_name,
          data: benchmarkSeries,
          color: controller.tailwindColors.blue[500],
          fillColor: controller.tailwindColors.blue[500],
          type: 'scatter',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<span class="font-medium text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-800 bg-opacity-20 rounded">${controller.optionsValue.company_name}: ${Math.round(this.y * 10) / 10}</span>`
            },
            useHTML: true
          }
        }
      }
    }

    const series = [
      companyBenchmarkSeries,
      {
        name: '90th Percentile',
        data: seriesDataMap.features_p90,
        color: controller.darkMode
          ? controller.tailwindColors.blue[200]
          : controller.tailwindColors.blue[800],
        type: 'spline',
        marker: {
          enabled: false
        },
        pointPlacement: 'on'
      },
      {
        name: '75th Percentile',
        data: seriesDataMap.features_p75,
        color: controller.darkMode
          ? controller.tailwindColors.blue[500]
          : controller.tailwindColors.blue[300],
        type: 'spline',
        marker: {
          enabled: false
        },
        pointPlacement: 'on'
      },
      {
        name: 'Median',
        data: seriesDataMap.features_p50,
        color: controller.darkMode
          ? controller.tailwindColors.blue[800]
          : controller.tailwindColors.blue[200],
        type: 'spline',
        marker: {
          enabled: false
        },
        pointPlacement: 'on'
      }
    ].filter(f => f)

    return {
      chart: {
        type: 'spline',
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 10,
        spacingBottom: 0,
        height: 364
      },
      title: {
        text: null
      },
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        itemStyle: {
          color: controller.darkMode
            ? controller.tailwindColors.gray[400]
            : controller.tailwindColors.gray[500]
        },
        itemHoverStyle: {
          color: controller.darkMode
            ? controller.tailwindColors.gray[300]
            : controller.tailwindColors.gray[400]
        }
      },
      xAxis: {
        categories,
        title: {
          text: controller.optionsValue.x_axis_label,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        tickLength: 0,
        labels: {
          padding: 10,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        crosshair: {
          color: Highcharts.color(controller.tailwindColors.blue[100])
            .setOpacity(controller.darkMode ? 0.1 : 0.5)
            .get('rgba'),
          dashStyle: 'ShortDash',
          width: 0
        }
      },
      yAxis: {
        title: {
          text: 'New units of code per developer per month',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: controller.darkMode
          ? controller.tailwindColors.gray[700]
          : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        crosshair: {
          color: controller.darkMode
            ? controller.tailwindColors.gray[700]
            : controller.tailwindColors.gray[200],
          dashStyle: 'ShortDash',
          width: 0
        }
      },
      plotOptions: {
        spline: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          marker: {
            enabled: false
          },
          fillOpacity: 0
        },
        scatter: {
          events: {
            mouseOver: function () {
              this.xAxis.update(
                {
                  crosshair: {
                    width: 2,
                    color: controller.darkMode
                      ? controller.tailwindColors.gray[700]
                      : controller.tailwindColors.gray[200]
                  }
                }
              )
              this.yAxis.update({ crosshair: { width: 2 } })
            },
            mouseOut: function () {
              this.xAxis.update({
                crosshair: {
                  width: 0,
                  color: Highcharts.color(controller.tailwindColors.blue[100])
                    .setOpacity(0.5)
                    .get('rgba')
                }
              })
              this.yAxis.update({ crosshair: { width: 0 } })
            }
          },
          marker: {
            enabled: true,
            radius: 5
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        outside: false,
        formatter: function () {
          if (this.series.userOptions.type === 'scatter') {
            return card({
              header: `<div class="text-gray-600 dark:text-gray-400">${controller.optionsValue.company_name}</div>`,
              primaryValues: [
                primaryValue({ name: 'NFPE', value: this.y.toFixed(1) }),
                primaryValue({ name: 'Developers', value: controller.parsedGraphData.company_benchmark.team_size })
              ]
            })
          } else {
            let engCount = this.key
            if (this.series.userOptions.type === 'column') {
              engCount = controller.parsedGraphData.company_benchmark.team_size
            }
            return card({
              header: `<div class="text-gray-600 dark:text-gray-400">${engCount} developers</div>`,
              breakDownValues: this.points.map((p) => {
                return breakDownValue({
                  style: `background-color: ${p.color};`,
                  name: p.series.name,
                  value: `${p.y.toFixed(1)} new units of code`,
                  type: 'line'
                })
              })
            })
          }
        }
      }
    }
  }
}
