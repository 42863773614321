export function benchmarkPlotLines (controller) {
  let plotLines = []
  if (controller.optionsValue.benchmark_data?.value) {
    plotLines = [
      {
        zIndex: 50,
        color: controller.darkMode ? controller.tailwindColors.teal[400] : controller.tailwindColors.teal[500],
        value: controller.optionsValue.benchmark_data.value,
        width: 2
      }
    ]
  }
  return plotLines
}
