import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class FeatureRatioChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const series = [
      {
        name: 'New',
        data: controller.parsedGraphData.new,
        marker: { enabled: false },
        color: this.categoricalColors[1]
      },
      {
        name: 'Churn',
        data: controller.parsedGraphData.churn,
        marker: { enabled: false },
        color: this.categoricalColors[2]
      },
      {
        name: 'Refactor',
        data: controller.parsedGraphData.refactor,
        marker: { enabled: false },
        color: this.categoricalColors[3]
      },
      {
        name: 'Other',
        data: controller.parsedGraphData.other,
        marker: { enabled: false },
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300]
      }
    ]

    return {
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        tickWidth: 0,
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        }
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          format: '{text}%',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return `${this.value}%`
          }
        },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        min: 0,
        max: 100,
        endOnTick: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          borderRadius: 5,
          states: {
            inactive: {
              enabled: false
            }
          },
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0])
                }
              }
            }
          }
        },
        areaspline: {
          marker: {
            enabled: false
          },
          fillColor: 'transparent'
        }
      },
      series,
      tooltip: {
        ...controller.baseToolTipConfig,
        shared: true,
        stickOnContact: true,
        formatter: function () {
          const primaryValues = []
          const breakDownValues = this.points.map((p) => {
            return breakDownValue({
              name: p.series.name,
              value: `${p.y}%`,
              style: `background-color: ${p.series.color};`,
              type: 'line'
            })
          })
          return card({
            date: `${Highcharts.dateFormat('%b \'%y', new Date(this.x))}`,
            primaryValues: [
              primaryValue({
                name: 'Work Breakdown',
                value: ''
              }),
              ...primaryValues
            ],
            breakDownValues,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-action="click->feature-ratio-chart#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`
          })
        }
      }
    }
  }
}
